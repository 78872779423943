<template>
  <div>
    <p class="text-2xl">
      SMS
    </p>
    <v-card>
      <v-form autocomplete="off">
        <validation-observer ref="observer">
          <div class="px-2">
            <v-card-text class="pt-5">
              <v-row class="pt-2">
                <v-col>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Nome da Campanha"
                        rules="required"
                        vid="name"
                      >
                        <v-text-field
                          v-model="name"
                          :error-messages="errors"
                          :hide-details="errors.length === 0"
                          label="Nome da Campanha"
                          outlined
                          dense
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      lg="3"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Tipo"
                        rules="required"
                        vid="type"
                      >
                        <v-select
                          v-model="typeSms"
                          :items="types"
                          :error-messages="errors"
                          :hide-details="errors.length === 0"
                          outlined
                          item-text="text"
                          item-value="value"
                          dense
                          label="Tipo"
                          @change="getSmsTemplates(typeSms)"
                        >
                          <template v-slot:item="{ item }">
                            <v-tooltip
                              right
                              open-on-hover
                              nudge-right="50"
                              max-width="320"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ item.text }}
                                </div>
                              </template>
                              <span>{{ item.description
                              }}</span>
                            </v-tooltip>
                          </template>
                        </v-select>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="3"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Tem Interação?"
                        rules="required"
                        vid="hasInteraction"
                      >
                        <v-select
                          v-model="hasInteraction"
                          :items="[{ value: true, text: 'Sim' }, { value: false, text: 'Não' }]"
                          :error-messages="errors"
                          :hide-details="errors.length === 0"
                          outlined
                          item-text="text"
                          dense
                          item-value="value"
                          label="Tem Interação?"
                          disabled
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Agendamento"
                        rules="required|max:160"
                        vid="sendDate"
                      >
                        <date
                          v-model="sendDate"
                          :error-messages="errors"
                          :hide-details="errors.length === 0"
                          :type="'datetime'"
                          dense
                          outlined
                          label="Agendamento"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      lg="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Template"
                        rules="required"
                        vid="smsTemplateId"
                      >
                        <v-select
                          v-model="smsTemplateId"
                          :items="templates"
                          :error-messages="errors"
                          :hide-details="errors.length === 0"
                          outlined
                          dense
                          item-text="name"
                          item-value="id"
                          label="Template"
                          @change="setMessageCardPhone"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="auto"
                      sm="12"
                    >
                      <v-card
                        class="mx-auto"
                        outlined
                      >
                        <v-card-text>
                          <v-textarea
                            v-model="phonesTextarea"
                            label="Telefones (DDD + 9º dígito + número)"
                            outlined
                            dense
                            hide-details
                            placeholder="11999999991
11999999992
11999999993
11999999994"
                            @input="processFileContent(phonesTextarea)"
                          />

                          <v-divider class="my-3" />
                          <input
                            ref="refInputEl"
                            type="file"
                            accept=".txt, .csv"
                            :hidden="true"
                            @change="getFile"
                          />
                          <v-btn
                            class="me-3"
                            color="primary"
                            @click="$refs.refInputEl.click()"
                          >
                            <v-icon class="d-sm-none">
                              {{ icons.mdiCloudUploadOutline }}
                            </v-icon>
                            <span class="d-none d-sm-block">Subir Contatos</span>
                          </v-btn>
                          <v-btn
                            color="error"
                            outlined
                            @click="resetContacts"
                          >
                            Resetar
                          </v-btn>

                          <br />
                          <div class="mt-2">
                            Cole a lista de telefones ou selecione um arquivo <code>(.txt/.csv)</code>. <br>
                            <code v-if="selectedFile"><b>Arquivo selecionado:</b> {{ selectedFile }}</code>
                          </div>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                          <p class="mb-0">
                            Total de números no arquivo: <b class="primary--text">{{ totalPhones }}</b> <br />
                            Total de números duplicados a serem removidos: <b class="primary--text">{{ duplicatePhones
                            }}</b> <br />
                            Total geral de números: <b class="primary--text">{{ phones.length }}</b>
                          </p>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="d-sm-flex justify-center position-relative"
                >
                  <div class="phone">
                    <div class="speaker"></div>
                    <div style="width: 425px; height: 771px">
                      <div class="center">
                        <div class="chat">
                          <div
                            id="chat"
                            class="messages"
                          >
                            <div
                              v-if="messageChat"
                              class="message parker"
                            >
                              <!-- eslint-disable vue/no-v-html -->
                              <span v-html="messageChat" />
                              <!--eslint-enable-->
                            </div>
                          </div>
                          <div class="input">
                            <input
                              placeholder="Enviar mensagem..."
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </div>

          <!-- divider -->
          <v-divider></v-divider>

          <div class="pa-3 ps-7">
            <!-- action buttons -->
            <v-card-text>
              <v-btn
                color="primary"
                class="me-3 mt-5"
                @click="store"
              >
                Salvar
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-5"
                @click="resetForm"
              >
                Limpar
              </v-btn>
            </v-card-text>
          </div>
        </validation-observer>
      </v-form>
    </v-card>

    <validation-observer
      ref="formCampaignSmsTemplate"
      autocomplete="off"
      tag="form"
      @keyup.enter="createSmsTemplate"
    >
      <dialog-form
        :status="dialogSmsTemplate"
        :is-update="false"
        :title="'SMS Template'"
        :width="1200"
        @action="createSmsTemplate"
        @close="dialogSmsTemplate = !dialogSmsTemplate"
      >
        <v-row>
          <v-col
            cols="6"
            sm="12"
            md="6"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Nome"
                  rules="required|max:100"
                  vid="form.name"
                >
                  <v-text-field
                    v-model="formSmsTemplate.name"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    label="Nome"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Tipo"
                  rules="required"
                  vid="type"
                >
                  <v-select
                    v-model="formSmsTemplate.type"
                    :items="smsTemplateTypes"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    outlined
                    item-text="text"
                    dense
                    item-value="value"
                    label="Tipo"
                    disabled
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Mensagem"
                  rules="required"
                  vid="form.message"
                >
                  <v-textarea
                    v-model="formSmsTemplate.message"
                    :counter-value="(value) => getMessageLenth(value)"
                    :error-messages="errors"
                    counter
                    label="Mensagem"
                    outlined
                  >
                  </v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            class="d-sm-flex justify-center position-relative"
          >
            <div class="phone">
              <div class="speaker"></div>
              <div style="width: 425px; height: 771px">
                <div class="center">
                  <div class="chat">
                    <div
                      id="chat"
                      class="messages"
                    >
                      <div
                        v-if="formSmsTemplateChat"
                        class="message parker"
                      >
                        <span v-html="formSmsTemplateChat" />
                      </div>
                    </div>
                    <div class="input">
                      <input
                        placeholder="Enviar mensagem..."
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </dialog-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
  smsTypes,
  smsTemplateType,
  smsTemplateTypes,
  smsTemplate as smsTemplateEnum,
} from '@/utils/enum'
import {
  getTotalPhones,
  groupByArrayAndRemoveDuplicates,
  fileToCsv,
  getPhonesFromCsv,
} from '@/utils/filePhones'

export default {
  setup() {
    const name = ref(null)
    const typeSms = ref(smsTypes[0].value)
    const sendDate = ref(null)
    const smsTemplateId = ref(null)
    const hasInteraction = ref(false)
    const message = ref('')
    const messageChat = ref('')
    const phones = ref([])
    const phonesTextarea = ref('')
    const duplicatePhones = ref(0)
    const totalPhones = ref(0)
    const templates = ref([])
    const selectedFile = ref(null)

    const dialogSmsTemplate = ref(false)
    const formSmsTemplateChat = ref('')
    const formSmsTemplate = ref({
      name: ref(''),
      type: ref(null),
      message: ref(''),
    })

    return {
      name,
      sendDate,
      smsTemplateId,
      typeSms,
      hasInteraction,
      message,
      messageChat,
      phones,
      phonesTextarea,
      duplicatePhones,
      totalPhones,
      templates,
      dialogSmsTemplate,
      formSmsTemplateChat,
      formSmsTemplate,
      smsTemplateTypes,
      selectedFile,
      icons: {
        mdiCloudUploadOutline,
      },
      types: smsTypes,
    }
  },

  watch: {
    message(value) {
      const text = value.replace(/(?:\r\n|\r|\n)/g, '<br>')

      this.messageChat = this.$utils.replaceSpecialCharacters(text)
    },

    // eslint-disable-next-line func-names
    'formSmsTemplate.message': function (value) {
      const text = value.replace(/(?:\r\n|\r|\n)/g, '<br>')

      this.formSmsTemplateChat = this.$utils.replaceSpecialCharacters(text)
    },
  },

  async created() {
    this.sendDate = this.$day().format('DD/MM/YYYY HH:mm')
    await this.getSmsTemplates()
  },

  methods: {
    ...mapActions('smsTemplates', {
      getTemplates: 'find',
      createTemplate: 'create',
    }),
    ...mapActions('serviceSms', [
      'create',
    ]),

    getMessageLenth(value) {
      return String(value.replace(/(?:\r\n|\r|\n)/g, '\n\n')).length
    },

    async openFormToCreate() {
      this.dialogSmsTemplate = true
      this.formSmsTemplateChat = ''
      this.formSmsTemplate = {
        name: null,
        type: [500].includes(this.typeSms) ? smsTemplateType.Bet : smsTemplateType.Default,
        message: '',
      }
    },

    resetContacts() {
      this.phones = []
      this.duplicatePhones = 0
      this.totalPhones = 0
      this.$refs.refInputEl.value = null
      this.selectedFile = null
    },

    resetForm() {
      this.name = null
      this.smsTemplateId = null
      this.sendDate = null
      this.typeSms = null
      this.message = ''
      this.messageChat = ''
      this.phones = []
      this.phonesTextarea = ''
      this.duplicatePhones = 0
      this.totalPhones = 0
      this.selectedFile = null
    },

    async getSmsTemplates() {
      const smsType = smsTypes.find(t => t.value === this.typeSms)
      const templates = await this.getTemplates({
        status: smsTemplateEnum.Approved,
      })

      this.templates = templates.filter(t => t.serviceIds.includes(smsType.serviceId))
    },

    async processFileContent(rows, type = 'text/plain') {
      let totalPhones = []
      let phones = []
      let duplicatePhones = 0

      if (type === 'text/csv') {
        totalPhones = fileToCsv(rows)
        phones = getPhonesFromCsv(totalPhones)
        duplicatePhones = totalPhones.length - phones.length
      } else {
        totalPhones = getTotalPhones(rows)
        phones = groupByArrayAndRemoveDuplicates(totalPhones)
        duplicatePhones = totalPhones.length - phones.length
      }

      this.phones = phones
      this.duplicatePhones = duplicatePhones
      this.totalPhones = totalPhones.length
    },

    async getFile(e) {
      if (!e.target.files || (e.target.files && !e.target.files[0])) {
        this.$notify(
          'Arquivo não importado.',
          'error',
        )

        return
      }

      const file = e.target.files[0]
      this.selectedFile = file.name

      if (!['text/plain', 'text/csv'].includes(file.type)) {
        this.$notify(
          'Selecione um arquivo (.txt/.csv).',
          'error',
        )

        return
      }

      const reader = new FileReader()
      reader.onload = e => this.processFileContent(e.target.result, file.type)
      reader.readAsText(file)
    },

    async setMessageCardPhone() {
      this.message = ''

      if (this.smsTemplateId === -1) {
        await this.openFormToCreate()
        this.messageChat = ''
        this.formSmsTemplate.message = ''
        this.smsTemplateId = null
      } else {
        const template = this.templates.find(t => t.id === this.smsTemplateId)

        if (!template) {
          this.$notify(
            'Conteúdo do template não encontrado.',
            'error',
          )

          return
        }

        this.message = template.message
      }
    },

    async store() {
      if (await this.$refs.observer.validate()) {
        if (!this.phonesTextarea && this.phones.length === 0) {
          this.$notify(
            'Informe uma lista de números ou selecione um arquivo (.txt/.csv).',
            'error',
          )

          return
        }

        const status = await this.create({
          name: this.name,
          sendDate: this.$day(this.sendDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
          typeSms: Number(this.typeSms),
          hasInteraction: this.hasInteraction,
          smsTemplateId: this.smsTemplateId,
          phones: this.phones,
        })

        if (status === 201) {
          this.$router.push('/report/service')
          this.resetForm()
          this.resetContacts()
        }
      }
    },

    async createSmsTemplate() {
      const body = {
        name: this.formSmsTemplate.name,
        type: this.formSmsTemplate.type,
        message: this.$utils.replaceSpecialCharacters(this.formSmsTemplate.message),
      }

      if (this.getMessageLenth(body.message) > 160) {
        this.$notify(
          'O campo mensagem pode conter até 160 caracteres.',
          'error',
        )

        return
      }

      if (await this.$refs.formCampaignSmsTemplate.validate()) {
        const { data } = await this.createTemplate(body)

        await this.getSmsTemplates()
        this.dialogSmsTemplate = false
        this.formSmsTemplateChat = ''
        this.smsTemplateId = data.id
        await this.setMessageCardPhone()
        this.formSmsTemplate = {
          name: null,
          type: null,
          message: '',
        }
      }
    },
  },
}
</script>
